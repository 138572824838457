###*
# @ngdoc object
# @name lpaFastTracking.controller:FastTrackingCtrl
#
# @description
#
###
class FastTrackingCtrl
  ### @ngInject ###
  constructor: (
    $scope
    $state
    $stateParams
    StatusManager
    $rootScope
    $log
    $filter
    MundoSocket
    MundoMap
    $timeout
    LpaMapUtils
    RestUtils
    DispatchUnitTypes
    DispUnitTypes
    MapSync
    Statuses
    DispUnitStatuses
    Tasks
    Incidents
    Routes
    Restangular
  ) ->

    # ----------------------------------------------------------
    # INITIALISATION OF THE WINDOW
    # ----------------------------------------------------------

    # Declare some default variables
    @detailsGroupIsOpen = true
    @showAllStatuses = true
    @statuses = {}
    @incidents = {}
    @features = {}

    $rootScope.unit = {}

    # Declare some stuff to the scope
    $scope.autoTracking = true
    $scope.speedZoom = false

    @dispatchUnitTypes = {}
    @dispatchUnitStatuses = {}

    angular.forEach DispUnitTypes, (dut) =>
      @dispatchUnitTypes[dut.id] = dut

    angular.forEach DispUnitStatuses, (dus) =>
      @dispatchUnitStatuses[dus.id] = dus

    # Create a map and override some default variables
    @map = MundoMap.createInstance 'dispatch-map',
      follow:
        enabled: true
        speedZoom: false
      search:
        enabled: false

    # Make some variables from layers
    @vehicleLayer = MundoMap.getLayerById(@map, 'markers')
    @incidentLayer = MundoMap.getLayerById(@map, 'incidents')
    @routesLayer = MundoMap.getLayerById(@map, 'routes')
    @trailLayer = MundoMap.getLayerById(@map, 'trails')

    LpaMapUtils.setIncidentLayerStyle @incidentLayer
    LpaMapUtils.setVehicleLayerStyle @vehicleLayer
    LpaMapUtils.setRoutesLayerStyle @routesLayer
    LpaMapUtils.setTrailsLayerStyle @trailLayer

    @unitId = $stateParams['unit_id']

    MapSync.units.enable
      map: @map
      markers: @features
      statuses: @statuses
      markerLayer: @vehicleLayer
      unit: @unitId
      routeLayer: @routesLayer
      incidents: @incidents
      incidentLayer: @incidentLayer
      trailLayer: @trailLayer

      onEventUpdate: (oldIncident) =>
        if !oldIncident
          # Create Route
          @setRouteOnMap()

    # Initially load the database saved status for this unit
    statusPromise = Statuses.one().get
      'filter[]': [
        "unit.id,EQ+#{@unitId}"
        ]
    statusPromise.then (statuses) =>
      if statuses.results.length > 0
        angular.forEach statuses.results, (status) =>
          @addStatusToMap status, true
          @map._options.follow.objects[status.unit.id] = @features[status.unit.id]

      @toggleShowAllStatuses()

    @popupOverlay = new ol.Overlay.Popup
      insertFirst: false
    @map.addOverlay @popupOverlay

    @map.getViewport().addEventListener "contextmenu", (e) =>
      LpaMapUtils.showContextMenu e, @map, $scope, @popupOverlay

    @addStatusToMap = (status) =>
      if (status && status.unit && status.unit.id) && status.unit.id == @unitId
        $rootScope.unit = status
        Restangular.all('assets').getList({'filter[]': ['id,' + status.asset.id]})
        .then (assets) ->
          $rootScope.unitAssetData = []
          $rootScope.unit.asset.data = []
          if angular.isArray assets
            if assets[0] && angular.isArray assets[0].assetData
              $rootScope.unitAssetData = assets[0].assetData.filter (item) ->
                !item.deletedAt?

      pan = ol.animation.pan
        duration: 500
        source: @map.getView().getCenter()

      zoom = ol.animation.zoom
        duration: 500
        resolution: @map.getView().getResolution()

      @map.beforeRender(pan)
      @map.beforeRender(zoom)

      LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, null, true, @trailLayer

      # Listens on the messageDataEventName and fires the function if called
      # Function is adding features to the map

      if (status && status.unit && status.unit.id) && status.unit.id == @unitId
        @setRouteOnMap()

    # ----------------------------------------------------------
    # CUSTOM HANDLERS
    # ----------------------------------------------------------

    @setRouteOnMap = () =>
      if $rootScope.unit && $rootScope.unit.unit && $rootScope.unit.unit.dispatchUnit
        dispatchUnitTaskPromise = RestUtils.getFullList Tasks,
          'filter[]': [
              "dispatchUnit.id," + $rootScope.unit.unit.dispatchUnit.id
              "taskType.code,INCIDENT"
              "closedAt,NULL"
              "incident.closedAt,NULL"
            ]
        dispatchUnitTaskPromise.then (results) =>
          angular.forEach results, (task) =>
            routePromise = RestUtils.getFullList Routes,
              'filter[]': [
                "task.id,EQ " + task.id
              ]
            routePromise.then (results) =>
              angular.forEach results, (route) =>
                if route.route?
                  MapSync.units.addVisible route.unit.id
                  LpaMapUtils.addProposalToMap route, null, @routesLayer

            if !@incidents or Object.keys(@incidents).length == 0
              Incidents.one(task.incident.id).get().then (incident) =>
                LpaMapUtils.addIncidentToMap incident, @features, @incidentLayer, @incidents
                MapSync.incidents.enable @map, @features, @incidents, @incidentLayer,
                  incident: incident.id
                  listen: ['incident_update', 'incident_close']
                  routes: @routesLayer
            else

              MapSync.incidents.enable @map, @features, @incidents, @incidentLayer,
                incident: @incidents[Object.keys(@incidents)[0]].id
                listen: ['incident_update', 'incident_close']
                routes: @routesLayer


    # ----------------------------------------------------------
    # EVENT HANDLERS
    # ----------------------------------------------------------

    # Handle double click events for opening detail screens of other stuff
    @map.on "click", (e) =>
      @map.forEachFeatureAtPixel e.pixel, (f, l) =>
        layerProps = l.getProperties()
        featureProps = f.getProperties()

        if layerProps._layerId is "markers"
          angular.forEach featureProps.features, (feature) ->
            # TODO: Do this in current page, update url and stuff.
            $state.go "fastTracking",
              unit_id: feature.getProperties()._status.unit.id
        if layerProps._layerId is "incidents"
          @openIncidentWindow featureProps._incident

    # Disable the auto tracking when the map is dragged around
    @map.on "pointerdrag", (e) =>
      if $scope.autoTracking is true
        $scope.autoTracking = false
        MundoMap.setFollow @map, $scope.autoTracking
        $scope.$apply()

    # ----------------------------------------------------------
    # HELPER FUNCTIONS
    # ----------------------------------------------------------

    # Completely remove a feature from the map
    @removeFeature = (feature, layer) ->
      MundoMap.getLayerSource layer
        .removeFeature feature

    @openIncidentWindow = (incident) ->
      url = $state.href('incidentTracking', {incident_id: incident['id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + incident['id'], 'width=800,height=600', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    @getActiveClass = (variable) ->
      if variable is true
        return "md-accent"
      else
        return "inactive"

    @getActiveTasks = (du) ->
      TaskPromise = Tasks.getList(
        "filter[]":
          "DispatchUnit": du
      )

    @isIgnitionTrue = () =>
      (@statuses && @statuses[@unitId] && @statuses[@unitId].sessions) &&
      (@statuses[@unitId].sessions.length > 0 && !@statuses[@unitId].sessions[0].stop?)

    @calculateElapsedTime = (date) ->
      (new Date()).getTime() - (new Date(date)).getTime()

    @tripBusyTime = () =>
      time = 0
      diff = 0
      if @isIgnitionTrue
        if @statuses[@unitId].sessions[0].start? && @statuses[@unitId].sessions[0].start.timestamp?
          diff = @calculateElapsedTime @statuses[@unitId].sessions[0].start.timestamp
        else
          diff = @calculateElapsedTime @statuses[@unitId].timestamp.sessions
        time = diff
      time

    @lastSessionDate = () =>
      if (@statuses && @statuses[@unitId])
        if @statuses[@unitId].sessions &&
        (@statuses[@unitId].sessions.length > 0 && @statuses[@unitId].sessions[0].stop? &&
        @statuses[@unitId].sessions[0].stop.timestamp?)
          moment($filter('amLocal')(
            $filter('amParse')(
              @statuses[@unitId].sessions[0].stop.timestamp)
            )
          ).format('DD-MM-Y, HH:mm:ss')
        else
          moment(
            $filter('amLocal')(
              $filter('amParse')(@statuses[@unitId].timestamp.sessions)
            )
          ).format('DD-MM-Y, HH:mm:ss')
      else
        moment().format('DD-MM-Y, HH:mm:ss')



    # ----------------------------------------------------------
    # TOGGLE FUNCTIONS (Functions that toggle variables)
    # ----------------------------------------------------------

    # Handles the checkbox that shows/hides all other statuses instead
    # of the one we are specifically following
    @toggleShowAllStatuses = () =>
      if @showAllStatuses
        # get all statuses from server
        RestUtils.getFullList(Statuses).then (statuses) =>
          # loop the statuses
          angular.forEach statuses, (status) =>
            # loop all assets of a status
            @addStatusToMap status

        MundoSocket.toggleUnitStatusConsumer true, true
        angular.forEach @features, (feature) =>
          if (feature.get '_status') && (feature.get '_status').unit.id != @unitId
            MundoMap.getLayerSource(@vehicleLayer).addFeature feature
            MundoMap.getLayerSource(@trailLayer).addFeature feature.get('_trail')
      else
        MundoSocket.toggleUnitStatusConsumer true, [@unitId]
        angular.forEach @features, (feature) =>
          if ((feature.get '_status') && (feature.get '_status').unit.id != @unitId)
            @removeFeature feature, @vehicleLayer
            @removeFeature feature.get('_trail'), @trailLayer

    # Toggles the zooming based on the speed of the vehicle
    @toggleSpeedZoom = () ->
      $scope.speedZoom = !$scope.speedZoom
      @map._options.follow.speedZoom = $scope.speedZoom

    # Toggles the automatic centering of the map based on the position of the vehicle
    @toggleAutoTracking = () =>
      $scope.autoTracking = !$scope.autoTracking
      MundoMap.setFollow @map, $scope.autoTracking

    # Shows/hides the details panel on the bottom
    @toggleDetails = (ev) =>
      ev.stopPropagation()
      @detailsGroupIsOpen = !@detailsGroupIsOpen

angular
  .module 'lpaFastTracking'
  .controller 'FastTrackingCtrl', FastTrackingCtrl
